import { createSlice } from '@reduxjs/toolkit';

import { PROD_ADMINS } from 'utils/constants';
import { shapeFilters, shapeUserEmail } from 'utils/utils';

export const containerKey = 'common';

export const initialState = {
  app: {
    mainPageUrl: null,
    pagination: {
      patientList: { perPage: 10, page: 1 },
      importPatients: { perPage: 10, page: 1 },
    },
    /** The selected partner by the user */
    partnerName: null,
    /** Navigation blocker (form changes) */
    navigationBlocker: {
      hasUnsavedChanges: false, // If there are unsaved changes on the current page
      showModal: false, // If the modal to confirm the unsaved changes is shown
      nextLocationToNavigate: null, // The next location to navigate to after the user confirms the missing changes
    },
  },
  partner: {
    onboardingFlows: [],
    scriptConfig: {},
    communications: {},
    communicationPreferences: {},
    locale: null,
    filters: {},
    id: null,
    name: null,
    tags: [],
    isActive: null,
    isPredictiveDialer: null,
    withSendKit: null,
    partnerDisplayName: null,
    callCenterPhoneNumber: null,
    product: null,
    withPcpConsent: null,
  },
  user: {
    clinicianEmail: null,
    clinicianFullName: null,
    isAdmin: false,
    /** A list of partners assigned to a user */
    partners: [],
  },
};

function shapeFiltersByEntity(filtersByEntity) {
  if (!filtersByEntity) {
    return {};
  }

  const shapedFilters = {};

  Object.entries(filtersByEntity).forEach(([entity, filter]) => {
    shapedFilters[entity] = shapeFilters(filter);
  });

  return shapedFilters;
}

const appConfigSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    setMainPageUrl(state, { payload }) {
      state.app.mainPageUrl = payload;
    },
    changePartnerName(state, { payload }) {
      if (state.app.partnerName === payload.partnerName) {
        return;
      }

      state.app.partnerName = payload.partnerName;
      // Reset navigation blocker
      state.app.navigationBlocker = initialState.app.navigationBlocker;
    },
    initUser(state, { payload }) {
      state.user = {
        clinicianEmail: payload.clinicianEmail,
        clinicianFullName: payload.clinicianFullName,
        isAdmin:
          process.env.NODE_ENV === 'production' &&
          process.env.HEALTHY_ENV?.includes('prod')
            ? PROD_ADMINS.includes(shapeUserEmail(payload.clinicianEmail))
            : true,
        partners: payload.partners,
      };
    },
    updatePartnerConfig(state, { payload }) {
      state.partner = {
        callCenterPhoneNumber: payload.callCenterPhoneNumber,
        communications: payload.communications,
        communicationPreferences: payload.communicationPreferences,
        filters: shapeFiltersByEntity(payload.filters),
        id: payload.id,
        isActive: payload.isActive,
        isPredictiveDialer: payload.isPredictiveDialer,
        locale: payload.locale,
        name: payload.name,
        onboardingFlows: payload.onboardingFlows,
        partnerDisplayName: payload.partnerDisplayName,
        product: payload.product,
        scriptConfig: payload.scriptConfig,
        tags: payload.tags,
        withSendKit: payload.withSendKit,
        withPcpConsent: payload.withPcpConsent,
      };
    },
    /* Pagination */
    updatePaginationPage(state, { payload: { pageName, page } }) {
      state.app.pagination[pageName].page = page;
    },
    updatePaginationPerPage(state, { payload: { pageName, perPage } }) {
      state.app.pagination[pageName].perPage = perPage;
      state.app.pagination[pageName].page = 1;
    },
    /* Navigation blocker */
    setNavigationBlocker(state, { payload }) {
      state.app.navigationBlocker = {
        ...state.app.navigationBlocker,
        ...payload,
      };
    },
  },
});

export const {
  setMainPageUrl,
  changePartnerName,
  initUser,
  updatePaginationPage,
  updatePaginationPerPage,
  updatePartnerConfig,
  setNavigationBlocker,
} = appConfigSlice.actions;
export const { reducer } = appConfigSlice;
