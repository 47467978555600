import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { Toaster as ReactHotToaster, toast as _toast } from 'react-hot-toast';

import { spacing, zIndex } from '../../theme';

import Alert from '../Alert/Alert';

const ANIMATION_DURATION = '200ms';

export const TOAST_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const TOAST_INTENT_BY_TYPE = {
  [TOAST_TYPES.ERROR]: 'danger',
  [TOAST_TYPES.INFO]: 'primary',
  [TOAST_TYPES.SUCCESS]: 'success',
  [TOAST_TYPES.WARNING]: 'warning',
};

const toasterCss = css`
  z-index: ${zIndex.toast};

  @keyframes Toast--slideInFromTop {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes Toast--fadeOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  &.HioToast--animateEnter {
    animation: Toast--slideInFromTop ${ANIMATION_DURATION} ease-out;
  }

  &.HioToast--animateExit {
    animation: Toast--fadeOut ${ANIMATION_DURATION} ease-in forwards;
  }

  margin-bottom: ${spacing.space3};
`;

/**
 * Dismiss all toasts.
 *
 * Be aware that it triggers the exit animation and does not remove the Toast instantly. Toasts will auto-remove after 1 second by default.
 */
function dismissAll() {
  _toast.dismiss();
}

/**
 * Remove all toasts instantly without any animations.
 */
function removeAll() {
  _toast.remove();
}

/**
 * Show an error toast.
 *
 * @param {string} message - A short message to display in the toast
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 * @param {React.ReactNode | string} options.extraContent - Extra content to display in the toast - below to the message
 */
function error(message, { duration, extraContent } = {}) {
  _toast(message, {
    duration,
    type: TOAST_TYPES.ERROR,
    extraContent,
  });
}

/**
 * Show an info toast.
 *
 * @param {string} message - A short message to display in the toast
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 * @param {React.ReactNode | string} options.extraContent - Extra content to display in the toast - below to the message
 */
function info(message, { duration, extraContent } = {}) {
  _toast(message, {
    duration,
    type: TOAST_TYPES.INFO,
    extraContent,
  });
}

/**
 * Show a success toast.
 *
 * @param {string} message - A short message to display in the toast
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 * @param {React.ReactNode | string} options.extraContent - Extra content to display in the toast - below to the message
 */
function success(message, { duration, extraContent } = {}) {
  _toast(message, {
    duration,
    type: TOAST_TYPES.SUCCESS,
    extraContent,
  });
}

/**
 * Show a warning toast.
 *
 * @param {string} message - A short message to display in the toast
 * @param {object} options
 * @param {number} options.duration - Delay in ms to close the toast
 * @param {React.ReactNode | string} options.extraContent - Extra content to display in the toast - below to the message
 */
function warning(message, { duration, extraContent } = {}) {
  _toast(message, {
    duration,
    type: TOAST_TYPES.WARNING,
    extraContent,
  });
}

/**
 * Toaster provides brief notifications. The component is also known as a snackbar.
 * Toasts inform users of a process that an app has performed. They appear temporarily and
 * shouldn't interrupt the user experience.
 *
 * The `<Toaster>` component needs to be added at the root of the app's component tree and it will render all toasts.
 * Note: All the toasts are related to the `Alert` component so you can use as an extraContent whatever you want inside `Alert.Content`.
 *
 * See more at
 * [react-hot-toast](https://react-hot-toast.com/)
 */
export function Toaster({ duration = 4000 }) {
  return (
    <ReactHotToaster
      gutter={0}
      position="top-right"
      toastOptions={{ duration }}
    >
      {({ id, type, message, visible, extraContent }) => (
        <div
          aria-live="polite"
          css={toasterCss}
          className={cx([
            'HioToast',
            {
              'HioToast--visible': visible,
            },
            {
              [`HioToast--${type}`]: type,
            },
            visible ? 'HioToast--animateEnter' : 'HioToast--animateExit',
          ])}
          role="status"
        >
          <Alert
            aria-live="polite"
            intent={TOAST_INTENT_BY_TYPE[type]}
            isClosable
            onClose={() => _toast.dismiss(id)}
          >
            {message && <Alert.Title>{message}</Alert.Title>}
            {extraContent && extraContent}
          </Alert>
        </div>
      )}
    </ReactHotToaster>
  );
}

Toaster.propTypes = {
  /** Delay in ms to close all toasts */
  duration: PropTypes.number,
};

export const toast = {
  dismissAll,
  error,
  info,
  removeAll,
  success,
  warning,
};

export default Toaster;
