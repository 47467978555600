import { createSlice } from '@reduxjs/toolkit';

export const containerKey = 'patientQueue';

export const initialState = {
  filters: {
    queueState: {
      filters: [],
      key: 'queueState',
      label: 'Patient State',
      type: 'multi',
    },
    cohortName: {
      filters: [],
      key: 'cohortName',
      label: 'Cohort Name',
      type: 'multi',
    },
    language: {
      filters: [],
      key: 'language',
      label: 'Language',
      type: 'multi',
    },
    clinicName: {
      filters: [],
      key: 'clinicName',
      label: 'Clinic Name',
      type: 'multi',
    },
    age: {
      filters: {},
      key: 'age',
      label: 'Age',
      type: 'range',
    },
  },
};

const patientQueueSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    removeFilter(state, { payload: { filterKey, groupKey } }) {
      state.filters[groupKey].filters = state.filters[groupKey].filters.filter(
        (key) => key !== filterKey,
      );
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
    updateFilters(state, { payload }) {
      Object.entries(payload).forEach(([key, filters]) => {
        state.filters[key].filters = filters;
      });
    },
  },
});

export const { removeFilter, resetFilters, updateFilters } =
  patientQueueSlice.actions;
export const { reducer } = patientQueueSlice;
