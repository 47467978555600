import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

import { borderRadius, colors, spacing } from '../../theme';

import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const checkboxRootCss = css`
  display: flex;
  align-items: center;
  column-gap: ${spacing.space2};
`;

const checkboxTriggerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: ${borderRadius.sm};
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  color: ${colors.white};
  border-color: ${colors.black};
  background-color: ${colors.white};

  &:hover:not(:disabled) {
    border-color: ${colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.gray600};
  }

  &[data-state='checked'] {
    background-color: ${colors.primary};
    border: unset;

    &:hover:not(:disabled) {
      background-color: ${colors.primary600};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const checkboxLabelCss = css`
  cursor: pointer;

  &::selection {
    color: unset;
    background-color: unset;
  }

  &.HioCheckbox__label--disabled {
    cursor: not-allowed;
    color: ${colors.gray600};
  }
`;

const checkboxIconCss = css`
  transform: translateY(1.5px);
`;

// Checkboxes allow users to select one or more items from a set of options.

/**
 * Checkboxes allow users to select one or more items from a set of options.
 *
 * @see
 * [Radix UI: Dropdown Menu](https://www.radix-ui.com/docs/primitives/components/dropdown-menu)
 */
const Checkbox = forwardRef(
  (
    {
      className,
      checked,
      defaultChecked,
      id,
      isDisabled,
      label,
      name,
      onChange,
      checkboxRootProps,
      value,
      ...restCheckboxProps
    },
    ref,
  ) => (
    <div
      css={checkboxRootCss}
      className={cx('HioCheckbox__root', className)}
      ref={ref}
      {...checkboxRootProps}
    >
      <RadixCheckbox.Root
        className={cx('HioCheckbox__trigger', {
          'HioCheckbox__trigger--disabled': isDisabled,
        })}
        checked={checked}
        css={checkboxTriggerCss}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        {...restCheckboxProps}
      >
        <RadixCheckbox.Indicator>
          <Icon css={checkboxIconCss} icon="check" size="xs" />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <Text
        as="label"
        className={cx('HioCheckbox__label', {
          'HioCheckbox__label--disabled': isDisabled,
        })}
        css={checkboxLabelCss}
        htmlFor={id}
        variant="body2"
      >
        {label}
      </Text>
    </div>
  ),
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  checkboxRootProps: PropTypes.object,
};

export default Checkbox;
