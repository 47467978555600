import { createSlice } from '@reduxjs/toolkit';

export const containerKey = 'patientList';

export const initialState = {
  perPage: 10,
  pageNumber: 0,
  filters: {
    queueState: {
      filters: [],
      key: 'queueState',
      label: 'Patient state',
      type: 'multi',
    },
  },
};

const patientListSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    removeFilter(state, { payload: { filterKey, groupKey } }) {
      state.filters[groupKey].filters = state.filters[groupKey].filters.filter(
        (key) => key !== filterKey,
      );
      state.pageNumber = 0;
    },
    resetFilters(state) {
      state.filters = initialState.filters;
      state.pageNumber = 0;
    },
    updateFilters(state, { payload }) {
      Object.entries(payload).forEach(([key, filters]) => {
        state.filters[key].filters = filters;
      });
      state.pageNumber = 0;
    },
    updatePageNumber(state, { payload }) {
      state.pageNumber = payload;
    },
    updatePerPage(state, { payload }) {
      state.perPage = payload;
      state.pageNumber = 0;
    },
  },
});

export const {
  removeFilter,
  resetFilters,
  updateFilters,
  updatePageNumber,
  updatePerPage,
} = patientListSlice.actions;

export const { reducer } = patientListSlice;
