import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';

import { Icons } from '../../constants';

import { toRem } from '../../helpers';

import { colors, spacing } from '../../theme';

import FormError from '../FormError/FormError';
import FormLabel from '../FormLabel/FormLabel';
import FormHint from '../FormHint/FormHint';

import ClearIndicator from './components/ClearIndicator';
import Control from './components/Control';
import DropdownIndicator from './components/DropdownIndicator';
import IndicatorSeparator from './components/IndicatorSeparator';
import Menu from './components/Menu';
import Option from './components/Option';
import Placeholder from './components/Placeholder';
import SingleValue from './components/SingleValue';
import MultiValue from './components/MultiValue';

const selectBaseOverrideCss = ({ isMulti }) => css`
  .HioSelect__value-container {
    padding: 0;
    ${isMulti && css`
      row-gap: ${spacing.space2};
      column-gap: ${spacing.space2};
    `}
  }

  .HioSelect__indicators {
    column-gap: ${spacing.space2};
  }

  .HioSelect__indicator {
    color: ${colors.black};
    padding: 0;

    &:hover {
      color: unset;
    }
  }

  .HioSelect__input-container {
    margin: 0;
    padding: 0;
    color: ${colors.black};

    .HioSelect__input {
      &:focus {
        all: unset;
      }
    }
  }

  .HioSelect__menu-notice {
    height: ${toRem(40)};
    display: flex;
    padding-inline: ${spacing.space3};
    align-items: center;
    justify-content: flex-start;
    color: ${colors.gray600};
  }
`;

/**
 * Select allows a user to choose one option from a list of options, or to enter a custom value, if enabled. It is a wrapper around [React Select](https://react-select.com/home),
 * this select supported async options, cache options, default options, clearable, disabled, error, loading, read only, required, searchable, open menu on focus, override dropdown icon, placeholder, value, and more.
 * for more information about the props, please check the [React Select documentation](https://react-select.com/props).
 */
const Select = forwardRef(
  (
    {
      asyncOptions,
      cacheOptions,
      className,
      defaultOptions,
      error,
      hint,
      id,
      isMulti,
      isClearable,
      isDisabled,
      isError,
      isLoading,
      isReadOnly,
      isRequired,
      isSearchable,
      label,
      loadingMessage,
      menuPlacement,
      name,
      noOptionsMessage,
      onBlur,
      onChange,
      onInputChange,
      openMenuOnFocus,
      options,
      overrideDropdownIcon,
      placeholder,
      value,
      selectRootProps,
      ...restSelectProps
    },
    ref,
  ) => {
    const _isClearable = Boolean(isClearable && !isReadOnly);

    const _isDisabled = Boolean(isDisabled);

    const _isError = Boolean(error || isError) && !isDisabled;

    const _isSearchable = Boolean(isSearchable && !isReadOnly);

    const _openMenuOnFocus = Boolean(openMenuOnFocus && !_isError);

    const SelectComponent = asyncOptions ? ReactAsyncSelect : ReactSelect;

    const commonComponents = {
      ClearIndicator,
      Control,
      DropdownIndicator,
      IndicatorSeparator,
      Menu,
      Placeholder,
      Option,
      SingleValue,
    };

    const asyncAdditions = {
      loadOptions: asyncOptions,
      cacheOptions,
      ...(defaultOptions && { defaultOptions }),
    };

    const syncAdditions = {
      options: options ?? [],
    };

    const multiSelectAdditions = {
      isMulti: true,
      closeMenuOnSelect: false,
      isClearable: true,
      hideSelectedOptions: false,
      components: {
        ...commonComponents,
        MultiValue,
      },
    };

    return (
      <div className={cx('HioSelect__root', className)} {...selectRootProps}>
        {/* Label */}
        {label && (
          <FormLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FormLabel>
        )}

        <SelectComponent
          aria-readonly={isReadOnly}
          classNamePrefix="HioSelect"
          components={commonComponents}
          css={selectBaseOverrideCss({ isMulti })}
          inputId={id}
          isClearable={_isClearable}
          isDisabled={_isDisabled}
          isErrored={_isError}
          isLoading={isLoading}
          isSearchable={_isSearchable}
          loadingMessage={() => loadingMessage}
          // If the select is read only, the menu is closed
          {...(isReadOnly && { menuIsOpen: false })}
          menuPlacement={menuPlacement}
          name={name}
          noOptionsMessage={() => noOptionsMessage}
          openMenuOnFocus={_openMenuOnFocus}
          onBlur={onBlur}
          onChange={onChange}
          onInputChange={onInputChange}
          overrideDropdownIcon={overrideDropdownIcon}
          placeholder={placeholder}
          ref={ref}
          value={value}
          {...(isMulti && multiSelectAdditions)}
          {...(asyncOptions && asyncAdditions)}
          {...(!asyncOptions && syncAdditions)}
          {...restSelectProps}
        />

        {/* Hint */}
        {hint && !error && <FormHint>{hint}</FormHint>}

        {/* Error */}
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);

Select.defaultProps = {
  // By default the select is without clear button and search input
  isClearable: false,
  isSearchable: false,
  // Default messages of the select
  loadingMessage: 'Loading...',
  // Menu placement is bottom by default but it can be top
  menuPlacement: 'bottom',
  // By default the select menu is closed
  noOptionsMessage: 'No results found',
  // By default the select menu is open on focus
  openMenuOnFocus: true,
  // By default the select as a default placeholder with text "Select..." so the default placeholder is null
  placeholder: null,
  // For passing props to the root element of the select
  selectRootProps: {},
};

Select.propTypes = {
  asyncOptions: PropTypes.oneOfType([
    PropTypes.func, // callback
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired,
    }), // Promise
  ]),
  cacheOptions: PropTypes.bool,
  className: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  loadingMessage: PropTypes.string,
  menuPlacement: PropTypes.oneOf(['bottom', 'top']),
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  overrideDropdownIcon: PropTypes.oneOf(Object.values(Icons)),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  ]),
  selectRootProps: PropTypes.object,
};

export default Select;
