import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

import { borderRadius, colors, spacing } from '../../theme';
import { toRem } from '../../helpers';

import Text from '../Text/Text';

import ButtonBase from '../ButtonBase/ButtonBase';
import Icon from '../Icon/Icon';

export const CHIP_VARIANTS = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
};

const basicChipCss = ({ isPill }) => css`
  appearance: none;
  margin: 0;

  /** Chip content */
  + div {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: ${spacing.space1};
    padding: ${spacing.space1} ${spacing.space2};
    border-radius: ${isPill ? toRem(20) : borderRadius.lg};
    border: 1px solid transparent;

    > *::selection {
      background-color: transparent;
    }
  }

  /** Disabled */
  &:disabled + div {
    cursor: not-allowed;
    opacity: 0.5;
  }

  /* * Focus */
  &:not(:disabled):focus-visible + div {
    outline: ${colors.blue300} solid ${toRem(3)};
  }

  /** Selected */
  &:checked + div {
    color: ${colors.white};
    background-color: ${colors.primary};
    border-color: transparent;
  }

  /** Selected  + Hovered */
  &:not(:disabled):hover:checked + div {
    color: ${colors.white};
    background-color: ${colors.primary500};
  }
`;

const variantCss = {
  /** Outlined */
  [CHIP_VARIANTS.OUTLINED]: css`
    &:not(:disabled):hover + div {
      background-color: ${colors.primary300};
    }

    + div {
      border-color: ${colors.primary200};
    }
  `,
  /** Contained */
  [CHIP_VARIANTS.CONTAINED]: css`
    &:not(:disabled):hover + div {
      background-color: ${colors.gray400};
    }

    + div {
      background-color: ${colors.gray300};
    }
  `,
};

const chipRemoveButtonCss = ({ isDisabled, isPill }) => css`
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  border-radius: ${isPill ? '50%' : borderRadius.sm};
  color: currentColor;

  &:hover,
  &:active {
    backdrop-filter: ${isDisabled ? 'none' : 'brightness(95%)'};
  }
`;

const Chip = forwardRef(
  (
    {
      checked,
      children,
      className,
      defaultSelected,
      id,
      isDisabled,
      isPill,
      isRemovable,
      name,
      onBlur,
      onChange,
      onRemove,
      value,
      variant,
      chipRootProps,
      ...restChipInputProps
    },
    ref,
  ) => (
    <label className={cx('HioChip__root', className)} {...chipRootProps}>
      <input
        css={[basicChipCss({ isPill }), variantCss[variant]]}
        className={cx('HioChip__input', {
          [`HioChip__input--${variant}`]: variant,
          'HioChip__input--pill': isPill,
          'HioChip__input--selected': checked || defaultSelected,
          'HioChip__input--disabled': isDisabled,
        })}
        defaultChecked={checked || defaultSelected}
        disabled={isDisabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        type="checkbox"
        value={value}
        {...restChipInputProps}
      />
      <div className="HioChip__content">
        <Text as="span" variant="body2">
          {children}
        </Text>
        {isRemovable && (
          <ButtonBase
            title="Remove"
            isDisabled={isDisabled}
            className="HioChip__remove-button"
            css={chipRemoveButtonCss({ isDisabled, isPill })}
            onClick={onRemove}
          >
            <Icon icon="x" size="xs" />
          </ButtonBase>
        )}
      </div>
    </label>
  ),
);

Chip.defaultProps = {
  variant: 'contained',
  isPill: false,
  isRemovable: false,
};

Chip.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultSelected: PropTypes.bool,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPill: PropTypes.bool,
  /** Whether the chip is removable */
  isRemovable: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  /** Callback fired when the remove button is clicked (use when pass `isRemovable`) */
  onRemove: PropTypes.func,
  value: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(CHIP_VARIANTS)),
  /** The option to add props to the root element of this component */
  chipRootProps: PropTypes.object,
};

export default Chip;
