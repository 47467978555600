import { createSlice } from '@reduxjs/toolkit';

import { CALL_DISPOSITION } from 'utils/constants';

export const containerKey = 'callPage';

const endCallInitialState = {
  callDisposition: CALL_DISPOSITION.ANSWERED,
  outcome: '',
  elaboration: '',
};

export const initialState = {
  currentScreenKey: '',
  currentScriptStep: '',
  showEndCallScreen: false,
  previousScreenKeys: [],
  screens: {},
  screensState: {},
  scriptSteps: [],
  scriptVariables: {},
  endCall: endCallInitialState,
};

const callPageSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    changeEndCallOutcome(
      state,
      { payload: { callDisposition, outcome, elaboration } },
    ) {
      state.endCall.callDisposition = callDisposition;
      state.endCall.outcome = outcome;
      state.endCall.elaboration = elaboration;
    },
    goToPreviousScreen(state) {
      const lastVisitedScreen = state.previousScreenKeys.pop();
      state.currentScreenKey = lastVisitedScreen;
      state.showEndCallScreen = false;
    },
    goToScreen(state, { payload }) {
      /**
       * 'endCall' is used when script navigation breaks the flow.
       * 'finishCall' is used as the final script navigation that leads to the end of call.
       */
      const isEndCall = payload === 'endCall';
      const isFinishCall = payload === 'finishCall';

      state.previousScreenKeys.push(state.currentScreenKey);

      if (!isEndCall) {
        state.currentScreenKey = payload;
      }

      state.showEndCallScreen = Boolean(isEndCall || isFinishCall);
    },
    initCallScript(state, { payload: { script } }) {
      const firstScriptStep = script.scriptSteps[0];
      state.scriptSteps = script.scriptSteps;
      state.screens = script.screens;
      state.currentScreenKey = script.firstScreen;
      state.currentScriptStep = firstScriptStep;
    },
    /**
     * An action that's being used when the user breaks out of the script flow
     * by clicking the "End call" button at the top of the script.
     * */
    endCall(state) {
      state.previousScreenKeys.push(state.currentScreenKey);
      state.showEndCallScreen = true;
    },
    resetState(state) {
      state.currentScreenKey = '';
      state.currentScriptStep = '';
      state.endCall = endCallInitialState;
      state.previousScreenKeys = [];
      state.screens = {};
      state.screensState = {};
      state.scriptSteps = [];
      state.scriptVariables = {};
      state.showEndCallScreen = false;
    },
    updateScreensState(state, { payload }) {
      state.screensState[state.currentScreenKey] = payload;
    },
    updateScriptVariables(state, { payload }) {
      state.scriptVariables = {
        ...state.scriptVariables,
        ...payload,
      };
    },
  },
});

export const {
  changeEndCallOutcome,
  endCall,
  goToPreviousScreen,
  goToScreen,
  initCallScript,
  resetState,
  updateScreensState,
  updateScriptVariables,
} = callPageSlice.actions;
export const { reducer } = callPageSlice;
