export const Icons = {
  ABORT_FLOW: 'abort-flow',
  ACTIVITY: 'activity',
  ARCHIVE: 'archive',
  ARROW_BACK: 'arrow-back',
  ARROW_BOUNCE: 'arrow-bounce',
  ARROW_RIGHT: 'arrow-right',
  BELL: 'bell',
  BRIEFCASE: 'briefcase',
  CALENDAR_USER: 'calendar-user',
  CALENDAR: 'calendar',
  CAMERA: 'camera',
  CARDS_QUESTION: 'cards-question',
  CHART_ANALYSIS: 'chart-analysis',
  CHART_BARS: 'chart-bars',
  CHART_LINES: 'chart-lines',
  CHART_PIE: 'chart-pie',
  CHECK_CIRCLE: 'check-circle',
  CHECK: 'check',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_SORT: 'chevron-sort',
  CHEVRON_UP: 'chevron-up',
  CIRCLE_WARNING: 'circle-warning',
  CLIPBOARD_CHECK: 'clipboard-check',
  CLIPBOARD_EXCLAMATION: 'clipboard-exclamation',
  CLIPBOARD_NOTES: 'clipboard-notes',
  CLOCK: 'clock',
  CLOUD_UPLOAD: 'cloud-upload',
  COLOR_BOARD: 'color-board',
  COPY: 'copy',
  DOCUMENT: 'document',
  ELLIPSIS_HORIZONTAL: 'ellipsis-horizontal',
  EMAIL: 'email',
  EXCLAMATION_CIRCLE: 'exclamation-circle',
  EXCLAMATION_TRIANGLE: 'exclamation-triangle',
  EYE: 'eye',
  FACE_ID: 'face-id',
  FILE_REPLACE: 'file-replace',
  FILE: 'file',
  FILTER: 'filter',
  FOLDER: 'folder',
  GLOBE: 'globe',
  GOOGLE_DRIVE: 'google-drive',
  GRID: 'grid',
  HEADSET: 'headset',
  HEART: 'heart',
  HOUSE_LANDLINE: 'house-landline',
  HOUSE_MOBILE: 'house-mobile',
  HOUSE_PHONE: 'house-phone',
  HOUSE: 'house',
  IMAGE: 'image',
  IMPORT_ALT: 'import-alt',
  IMPORT: 'import',
  INBOX: 'inbox',
  LANDLINE_ALT: 'landline-alt',
  LANDLINE: 'landline',
  LAYERS: 'layers',
  LIKE: 'like',
  LINK: 'link',
  LIST_UL: 'list-ul',
  LOCATION_PIN: 'location-pin',
  LOCK: 'lock',
  LOG_OUT: 'log-out',
  MAGIC_WOUND: 'magic-wound',
  MAILBOX: 'mailbox',
  MAXIMIZE: 'maximize',
  MESSAGE: 'message',
  MINIMIZE: 'minimize',
  MINUTEFUL_KIT: 'minuteful-kit',
  MOBILE: 'mobile',
  OUTREACH: 'outreach',
  PAPER_CLIP: 'paper-clip',
  PAUSE_CIRCLE: 'pause-circle',
  PDF: 'pdf',
  PENCIL: 'pencil',
  PHONE_DOWN: 'phone-down',
  PHONE_INCOMING: 'phone-incoming',
  PHONE_OUTGOING: 'phone-outgoing',
  PHONE_VOICEMAIL: 'phone-voicemail',
  PHONE_X: 'phone-x',
  PHONE: 'phone',
  PLAY_CIRCLE: 'play-circle',
  PLUS: 'plus',
  PRINTER: 'printer',
  QR_CODE: 'qr-code',
  QUESTION_CIRCLE: 'question-circle',
  SEARCH: 'search',
  SETTINGS: 'settings',
  SMS_DISABLED: 'sms-disabled',
  SMS: 'sms',
  STETHOSCOPE: 'stethoscope',
  STICKY_NOTE: 'sticky-note',
  STOP_CIRCLE: 'stop-circle',
  SUPPORT: 'support',
  SYNC: 'sync',
  THUMB_UP: 'thumb-up',
  TRASH: 'trash',
  TRUCK: 'truck',
  UPLOAD: 'upload',
  UPLOAD_ALT: 'upload-alt',
  URINE_CUP: 'urine-cup',
  USER_CHECK: 'user-check',
  USER_CIRCLE: 'user-circle',
  USER: 'user',
  USER_X: 'user-x',
  VIDEO_CAMERA: 'video-camera',
  VOICEMAIL: 'voicemail',
  VOLUME_MUTE: 'volume-mute',
  VOLUME: 'volume',
  X_CIRCLE: 'x-circle',
  X: 'x',
};

export const Sizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
};

export const MODAL_SIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const FLEX_UTILS = {
  DIRECTIONS: {
    ROW: 'row',
    COLUMN: 'column',
  },
  ALIGN_ITEMS: {
    STRETCH: 'stretch',
    BASELINE: 'baseline',
    START: 'start',
    CENTER: 'center',
    END: 'end',
  },
  JUSTIFY_CONTENT: {
    STRETCH: 'stretch',
    START: 'start',
    CENTER: 'center',
    END: 'end',
    SPACE_AROUND: 'space-around',
    SPACE_BETWEEN: 'space-between',
  },
};
