import { createSlice } from '@reduxjs/toolkit';

export const containerKey = 'pcpCenter';

export const initialState = {
  perPage: 10,
  pageNumber: 0,
};

const pcpCenterSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    updatePageNumber(state, { payload }) {
      state.pageNumber = payload;
    },
    updatePerPage(state, { payload }) {
      state.perPage = payload;
      state.pageNumber = 0;
    },
  },
});

export const { updatePageNumber, updatePerPage } = pcpCenterSlice.actions;
export const { reducer } = pcpCenterSlice;
