import { css } from '@emotion/react';

import 'flatpickr/dist/flatpickr.min.css';

import { spacing } from '@packages/ui';

import { colors, fontSize } from 'styles/helpers';

export default css`
  // Inline datepicker
  .inline {
    border: 1px solid ${colors.blueGray500};
    border-radius: 4px;
  }

  .flatpickr-wrapper {
    width: 100%;
  }

  .flatpickr-calendar {
    width: 100%;
    font-family: inherit;

    &.static {
      &.open {
        position: absolute;
        top: 48px;
      }
    }

    * {
      color: ${colors.text};
    }

    &.inline {
      max-width: 100%;
    }
  }

  .flatpickr-months {
    .flatpickr-month {
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .flatpickr-current-month {
    position: unset;

    span.cur-month {
      font-weight: 400;
      font-size: 14px;

      :hover {
        background-color: transparent;
      }
    }

    .numInputWrapper:hover {
      background-color: transparent;
    }

    input.cur-year {
      pointer-events: none;
    }
  }

  .flatpickr-months .flatpickr-next-month svg,
  .flatpickr-months .flatpickr-prev-month svg {
    fill: ${colors.text};
    height: 13px;
    width: 13px;
  }

  .flatpickr-months .flatpickr-next-month:hover svg,
  .flatpickr-months .flatpickr-prev-month:hover svg {
    fill: ${colors.text};
  }

  .flatpickr-day.today:focus,
  .flatpickr-day.today:hover {
    border-color: transparent;
    background: ${colors.light};
    color: ${colors.gray};
  }

  .flatpickr-calendar.hasTime .flatpickr-time {
    height: 60px;
    max-height: unset;
    margin: 0 33px;
    align-items: center;
  }

  .flatpickr-time input {
    border-radius: 4px;

    // Remove custom spinner arrows
    ::-webkit-inner-spin-button {
      appearance: auto;
    }

    &.flatpickr-hour {
      font-weight: 400;
    }
  }

  // Remove custom spinner arrows
  .numInputWrapper span {
    display: none;
  }

  .flatpickr-time .flatpickr-am-pm {
    border-radius: 4px;
  }

  // Remove time separator
  .flatpickr-time-separator {
    display: none;
  }

  .flatpickr-time {
    .numInputWrapper {
      border-right: 1px solid ${colors.gray500};
      margin-right: 6px;
      padding-right: 6px;
      background: transparent;
    }

    .flatpickr-am-pm:hover {
      background-color: ${colors.gray500};
    }

    input,
    focus {
      &:hover,
      &:focus {
        background-color: ${colors.gray500};
      }
    }
  }

  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: unset;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay:focus,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.today.inRange,
  .flatpickr-day:focus,
  .flatpickr-day:hover {
    &:not(.flatpickr-disabled) {
      background: ${colors.gray500};
      color: ${colors.text};
    }
  }

  .flatpickr-months .flatpickr-next-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    right: 16px;
  }

  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 16px;
  }

  .flatpickr-months .flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    top: 14px;
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    color: ${colors.blueGray500};
  }

  .flatpickr-months .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover {
    background-color: ${colors.gray500};
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    font-size: ${fontSize.sm};
    font-weight: 400;
  }

  .flatpickr-current-month input.cur-year {
    font-size: ${fontSize.sm};
    font-weight: 400;
    margin-left: 8px;
  }

  span.flatpickr-weekday {
    color: ${colors.text};
    font-weight: 500;
    font-size: ${fontSize.sm};
  }

  .flatpickr-rContainer {
    width: 100%;
  }

  .flatpickr-days {
    width: 100%;

    .dayContainer {
      width: 100%;
      max-width: unset;
      min-width: unset;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .flatpickr-day {
      width: 100%;
      margin: auto;
    }
  }

  .flatpickr-day {
    &.selected,
    &.selected:focus,
    &.selected:hover {
      background: ${colors.primary};
      color: ${colors.white};
      border-color: transparent;
    }
  }

  .flatpickr-day.today {
    background: ${colors.gray100};
    color: ${colors.gray600};
    border-color: transparent;
  }

  .flatpickr-innerContainer {
    padding: ${spacing.space5} ${spacing.space6};
  }

  .flatpickr-day {
    border-radius: 4px;
  }
`;
