import PropTypes from 'prop-types';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

import Button from '../../Button/Button';

import Heading from '../../Heading/Heading';

import Icon from '../../Icon/Icon';

import { spacing } from '../../../theme';
import { Icons } from '../../../constants';

const modalHeaderCss = css`
  padding-inline: ${spacing.space5};
  margin-block-end: ${spacing.space4};
`;

const modalHeaderContentCss = ({ withIcon }) => css`
  display: flex;

  ${withIcon &&
  css`
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing.space4};
  `}
`;

const modalCloseButtonCss = css`
  position: absolute;
  top: ${spacing.space5};
  right: ${spacing.space5};
`;

/**
 * The modal header.
 */
const Header = forwardRef(
  ({ className, withCloseButton, icon, title, ...restProps }, ref) => {
    const withIcon = Boolean(icon);

    return (
      <div
        css={modalHeaderCss}
        className={cx('HioModal__header', className)}
        {...restProps}
      >
        <div
          css={modalHeaderContentCss({ withIcon })}
          className={cx('HioModal__header-content', {
            'HioModal__header-content--with-icon': withIcon,
          })}
        >
          {withIcon && <Icon icon={icon} size="lg" />}
          <RadixDialog.Title asChild ref={ref}>
            <Heading as="h4" flushMargin>
              {title}
            </Heading>
          </RadixDialog.Title>
        </div>
        {withCloseButton && (
          <RadixDialog.Close
            asChild
            className={cx('HioModal__close-btn', className)}
            ref={ref}
          >
            <Button
              size="sm"
              appearance="text"
              css={modalCloseButtonCss}
              isIcon
            >
              <Icon icon="x" size="xs" />
            </Button>
          </RadixDialog.Close>
        )}
      </div>
    );
  },
);

Header.defaultProps = {
  withCloseButton: false,
};

Header.propTypes = {
  className: PropTypes.string,
  /** For adding icon to the Header */
  icon: PropTypes.oneOf(Object.values(Icons)),
  /** For adding close button to the Header */
  withCloseButton: PropTypes.bool,
  /** The title of the modal */
  title: PropTypes.string.isRequired,
};

Header.displayName = 'Modal.Header';

export default Header;
