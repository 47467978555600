import { css } from '@emotion/react';

export default css`
  :root {
    /* -------------------
          Font sizes
    -------------------- */
    --font-xs: 12px;
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 20px;
    --font-xl: 30px;
    --font-xxl: 40px;

    /* -------------------
            z-index
    -------------------- */
    --z-index-page-loader: 800;
    --z-index-header: 1000;
    --z-index-menu: 1060;
  }
`;
