import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import {
  borderRadius,
  colors,
  spacing,
  fontSizes,
  elevations,
  zIndex,
} from '../../theme';
import { toRem } from '../../helpers';

const contentCss = css`
  color: ${colors.white};
  background-color: ${colors.black};
  border-radius: ${borderRadius.lg};
  padding: ${spacing.space2} ${spacing.space3};
  max-width: ${toRem(350)};
  line-height: 1.5;
  box-shadow: ${elevations.elevation200};
  font-size: ${fontSizes.caption};
  z-index: ${zIndex.tooltip};

  @keyframes Tooltip--slideUpAndFade {
    from {
      transform: translateY(2px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation: Tooltip--slideUpAndFade 200ms ease-out;
`;

/**
 * A tooltip is a message box that is displayed when a user hovers over or gives focus to a UI element.

 * @see
 * [Radix UI: Tooltip](https://www.radix-ui.com/docs/primitives/components/tooltip)
 */
const Tooltip = forwardRef(({ children, content }, ref) => (
  <RadixTooltip.Provider delayDuration={250} ref={ref}>
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild className="HioTooltip__trigger">
        {children}
      </RadixTooltip.Trigger>
      {content && (
        <RadixTooltip.Content
          className="HioTooltip__content"
          css={contentCss}
          sideOffset={6}
        >
          {content}
        </RadixTooltip.Content>
      )}
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
));

Tooltip.propTypes = {
  children: PropTypes.node,
  /** Content of the tooltip */
  content: PropTypes.string,
};

export default Tooltip;
