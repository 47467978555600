import Lottie from 'react-lottie';

import { PageLoader as UiPageLoader } from '@packages/urine';

import animationData from './loader-animation.json';

function PageLoader() {
  return (
    <UiPageLoader
      loaderComponent={
        <Lottie
          options={{ animationData, loop: true }}
          height={70}
          width={70}
        />
      }
    />
  );
}

export default PageLoader;
