import { Icons } from '../../constants';
import {
  AbortFlow,
  Activity,
  Archive,
  ArrowBack,
  ArrowBounce,
  ArrowRight,
  Bell,
  Briefcase,
  CalendarUser,
  Calendar,
  Camera,
  CardsQuestion,
  ChartAnalysis,
  ChartBars,
  ChartLines,
  ChartPie,
  CheckCircle,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronSort,
  ChevronUp,
  CircleWarning,
  ClipboardCheck,
  ClipboardExclamation,
  ClipboardNotes,
  Clock,
  CloudUpload,
  ColorBoard,
  Copy,
  Document,
  EllipsisHorizontal,
  Email,
  ExclamationCircle,
  ExclamationTriangle,
  Eye,
  FaceId,
  FileReplace,
  File,
  Filter,
  Folder,
  Globe,
  GoogleDrive,
  Grid,
  Headset,
  Heart,
  HouseLandline,
  HouseMobile,
  HousePhone,
  House,
  Image,
  ImportAlt,
  Import,
  Inbox,
  LandlineAlt,
  Landline,
  Layers,
  Like,
  Link,
  ListUl,
  LocationPin,
  Lock,
  LogOut,
  MagicWound,
  Mailbox,
  Minimize,
  Message,
  Maximize,
  MinutefulKit,
  Mobile,
  Outreach,
  PaperClip,
  PauseCircle,
  Pdf,
  Pencil,
  PhoneDown,
  PhoneIncoming,
  PhoneOutgoing,
  PhoneVoicemail,
  PhoneX,
  Phone,
  PlayCircle,
  Plus,
  Printer,
  QrCode,
  QuestionCircle,
  Search,
  Settings,
  SmsDisabled,
  Sms,
  Stethoscope,
  StickyNote,
  StopCircle,
  Support,
  Sync,
  ThumbUp,
  Trash,
  Truck,
  Upload,
  UploadAlt,
  UrineCup,
  UserCheck,
  UserCircle,
  User,
  UserX,
  VideoCamera,
  Voicemail,
  Volume,
  VolumeMute,
  XCircle,
  X,
} from './icons';

const IconComponent = {
  [Icons.ABORT_FLOW]: AbortFlow,
  [Icons.ACTIVITY]: Activity,
  [Icons.ARCHIVE]: Archive,
  [Icons.ARROW_BACK]: ArrowBack,
  [Icons.ARROW_BOUNCE]: ArrowBounce,
  [Icons.ARROW_RIGHT]: ArrowRight,
  [Icons.BELL]: Bell,
  [Icons.BRIEFCASE]: Briefcase,
  [Icons.CALENDAR_USER]: CalendarUser,
  [Icons.CALENDAR]: Calendar,
  [Icons.CAMERA]: Camera,
  [Icons.CARDS_QUESTION]: CardsQuestion,
  [Icons.CHART_ANALYSIS]: ChartAnalysis,
  [Icons.CHART_BARS]: ChartBars,
  [Icons.CHART_LINES]: ChartLines,
  [Icons.CHART_PIE]: ChartPie,
  [Icons.CHECK_CIRCLE]: CheckCircle,
  [Icons.CHECK]: Check,
  [Icons.CHEVRON_DOWN]: ChevronDown,
  [Icons.CHEVRON_LEFT]: ChevronLeft,
  [Icons.CHEVRON_RIGHT]: ChevronRight,
  [Icons.CHEVRON_SORT]: ChevronSort,
  [Icons.CHEVRON_UP]: ChevronUp,
  [Icons.CIRCLE_WARNING]: CircleWarning,
  [Icons.CLIPBOARD_CHECK]: ClipboardCheck,
  [Icons.CLIPBOARD_EXCLAMATION]: ClipboardExclamation,
  [Icons.CLIPBOARD_NOTES]: ClipboardNotes,
  [Icons.CLOCK]: Clock,
  [Icons.CLOUD_UPLOAD]: CloudUpload,
  [Icons.COLOR_BOARD]: ColorBoard,
  [Icons.COPY]: Copy,
  [Icons.DOCUMENT]: Document,
  [Icons.ELLIPSIS_HORIZONTAL]: EllipsisHorizontal,
  [Icons.EMAIL]: Email,
  [Icons.EXCLAMATION_CIRCLE]: ExclamationCircle,
  [Icons.EXCLAMATION_TRIANGLE]: ExclamationTriangle,
  [Icons.EYE]: Eye,
  [Icons.FACE_ID]: FaceId,
  [Icons.FILE_REPLACE]: FileReplace,
  [Icons.FILE]: File,
  [Icons.FILTER]: Filter,
  [Icons.FOLDER]: Folder,
  [Icons.GLOBE]: Globe,
  [Icons.GOOGLE_DRIVE]: GoogleDrive,
  [Icons.GRID]: Grid,
  [Icons.HEADSET]: Headset,
  [Icons.HEART]: Heart,
  [Icons.HOUSE_LANDLINE]: HouseLandline,
  [Icons.HOUSE_MOBILE]: HouseMobile,
  [Icons.HOUSE_PHONE]: HousePhone,
  [Icons.HOUSE]: House,
  [Icons.IMAGE]: Image,
  [Icons.IMPORT_ALT]: ImportAlt,
  [Icons.IMPORT]: Import,
  [Icons.INBOX]: Inbox,
  [Icons.LANDLINE_ALT]: LandlineAlt,
  [Icons.LANDLINE]: Landline,
  [Icons.LAYERS]: Layers,
  [Icons.LIKE]: Like,
  [Icons.LINK]: Link,
  [Icons.LIST_UL]: ListUl,
  [Icons.LOCATION_PIN]: LocationPin,
  [Icons.LOCK]: Lock,
  [Icons.LOG_OUT]: LogOut,
  [Icons.MAGIC_WOUND]: MagicWound,
  [Icons.MAXIMIZE]: Maximize,
  [Icons.MESSAGE]: Message,
  [Icons.MINIMIZE]: Minimize,
  [Icons.MAILBOX]: Mailbox,
  [Icons.MINUTEFUL_KIT]: MinutefulKit,
  [Icons.MOBILE]: Mobile,
  [Icons.OUTREACH]: Outreach,
  [Icons.PAPER_CLIP]: PaperClip,
  [Icons.PAUSE_CIRCLE]: PauseCircle,
  [Icons.PDF]: Pdf,
  [Icons.PENCIL]: Pencil,
  [Icons.PHONE_DOWN]: PhoneDown,
  [Icons.PHONE_INCOMING]: PhoneIncoming,
  [Icons.PHONE_OUTGOING]: PhoneOutgoing,
  [Icons.PHONE_VOICEMAIL]: PhoneVoicemail,
  [Icons.PHONE_X]: PhoneX,
  [Icons.PHONE]: Phone,
  [Icons.PLAY_CIRCLE]: PlayCircle,
  [Icons.PLUS]: Plus,
  [Icons.PRINTER]: Printer,
  [Icons.QR_CODE]: QrCode,
  [Icons.QUESTION_CIRCLE]: QuestionCircle,
  [Icons.SEARCH]: Search,
  [Icons.SETTINGS]: Settings,
  [Icons.SMS_DISABLED]: SmsDisabled,
  [Icons.SMS]: Sms,
  [Icons.STETHOSCOPE]: Stethoscope,
  [Icons.STICKY_NOTE]: StickyNote,
  [Icons.STOP_CIRCLE]: StopCircle,
  [Icons.SUPPORT]: Support,
  [Icons.SYNC]: Sync,
  [Icons.THUMB_UP]: ThumbUp,
  [Icons.TRASH]: Trash,
  [Icons.TRUCK]: Truck,
  [Icons.UPLOAD]: Upload,
  [Icons.UPLOAD_ALT]: UploadAlt,
  [Icons.URINE_CUP]: UrineCup,
  [Icons.USER_CHECK]: UserCheck,
  [Icons.USER_CIRCLE]: UserCircle,
  [Icons.USER]: User,
  [Icons.USER_X]: UserX,
  [Icons.VIDEO_CAMERA]: VideoCamera,
  [Icons.VOICEMAIL]: Voicemail,
  [Icons.VOLUME]: Volume,
  [Icons.VOLUME_MUTE]: VolumeMute,
  [Icons.X_CIRCLE]: XCircle,
  [Icons.X]: X,
};

export default IconComponent;
