import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { css } from '@emotion/react';
import { cx } from '@emotion/css';
import { toSpacing } from '../../helpers';
import Paper from '../Paper/Paper';
import { colors, elevations, zIndex } from '../../theme';

// TODO: Decide what to do with min-width. This will become an issue once Select is using Dropdown - for now there is not min-width definition.
const contentCss = css`
  margin-block-start: ${toSpacing.space1};

  z-index: ${zIndex.dropdown};

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const paperCss = css`
  border-color: ${colors.gray400};
  box-shadow: ${elevations.elevation200};
`;

function Content({ children, className }) {
  return (
    <RadixDropdown.Content
      align="start"
      className={cx(className, 'HioDropdown__content')}
      css={contentCss}
    >
      <Paper css={paperCss}>{children}</Paper>
    </RadixDropdown.Content>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Content.displayName = 'Dropdown.Content';

export default Content;
