import queryString from 'query-string';

import { reporter } from '@packages/reporter';

import {
  removeAuthRelatedQueryString,
  createUMExternalAuthProviderUrl,
  saveExternalPatientIdToStorage,
  replaceAndRedirectUrl,
  EXTERNAL_PROVIDERS,
} from './utils';
import exchangeSingleUseToken from './services/exchangeSingleUseToken';

/**
 * Config modal def.
 * @typedef {Object} Config - Configuration options
 * @property {string[]?} config.externalAuthProviders - External auth providers the app supports
 * @property {string} config.singleUseTokenUrl - Url to query when supporting single token flow
 * @property {string} config.userClaimsUrl - Url to fetch user claims
 * @property {object} config.userClaimsMultiRegion - Map of region based urls to fetch user claims
 * @property {string} config.userClaimsMultiRegion.uk - UK BE URL
 * @property {string} config.userClaimsMultiRegion.us - US BE URL
 * @property {object?} config.singleUseTokenMultiRegion - Map of region based urls when supporting single token flow with multi regions
 * @property {string?} config.singleUseTokenMultiRegion.uk - UK BE URL
 * @property {string} config.singleUseTokenMultiRegion.us - US BE URL
 * @property {string} clientId - UM client ID
 * @property {string} redirectUri- UM redirect uri after successful authentication
 */

/**
 * Get single use token url
 * @param {Config} config
 * @param {string} region - Query param needed for multi region support
 *
 * @returns {string?} The URL to use for single use token flow
 */
function getSingleUseTokenUrl(config, region) {
  switch (true) {
    case Boolean(config?.singleUseTokenUrl):
      return config.singleUseTokenUrl;
    case Boolean(config?.singleUseTokenMultiRegion && region):
      return config.singleUseTokenMultiRegion[region];
    default:
      return null;
  }
}

/**
 * Get external providers url
 * @param {Config} config - Configuration options
 * @param {object} options - Additional options
 * @param {string} options.clientId - UM client ID
 * @param {string} options.redirectUri - UM redirect uri after successful authentication
 * @param {string} options.ap - Auth provider name
 * @param {string} options.iss - Relevant for epic auth provider
 * @param {string} options.jwt - Relevant for dr_chrono auth provider
 * @param {string} options.launch - Relevant for epic auth provider
 *
 * @returns {string?} The URL to use for external provider flow
 */
function getAuthProvidersUrl(
  config,
  { clientId, redirectUri, ap, iss, jwt, launch },
) {
  switch (true) {
    case ap === EXTERNAL_PROVIDERS.EPIC &&
      config.externalAuthProviders.includes(EXTERNAL_PROVIDERS.EPIC):
      return createUMExternalAuthProviderUrl(EXTERNAL_PROVIDERS.EPIC, {
        clientId,
        redirectUri,
        ap,
        iss,
        launch,
      });

    case ap === EXTERNAL_PROVIDERS.CERNER &&
      config.externalAuthProviders.includes(EXTERNAL_PROVIDERS.CERNER):
      return createUMExternalAuthProviderUrl(EXTERNAL_PROVIDERS.CERNER, {
        clientId,
        redirectUri,
        ap,
        iss,
        launch,
      });

    case ap === 'maccabi_azure' &&
      config.externalAuthProviders.includes(EXTERNAL_PROVIDERS.MCB):
      return createUMExternalAuthProviderUrl(EXTERNAL_PROVIDERS.MCB, {
        clientId,
        redirectUri,
        ap,
      });

    case ap === EXTERNAL_PROVIDERS.DR_CHRONO &&
      config.externalAuthProviders.includes(EXTERNAL_PROVIDERS.DR_CHRONO):
      return createUMExternalAuthProviderUrl(EXTERNAL_PROVIDERS.DR_CHRONO, {
        clientId,
        redirectUri,
        ap,
        jwt,
      });
    default:
      return null;
  }
}

/**
 * Get auth token based on query strings.
 * TODO: This is the link for the [flow control](https://app.diagrams.net/#G1HGbZoQz2BrZzreGys79tv-k4djiu3usd)
 * Replace with URL to docs once we move it there
 * @param {Config} config - Configuration options
 * @param {string} clientId - UM client ID
 * @param {string} redirectUri- UM redirect uri after successful authentication
 * @param {function} onError - Callback to be used when encountering UM errors
 *
 * @returns {string?} A valid auth token if acquired
 */
export default async function getInitialAuthToken({
  options,
  clientId,
  redirectUri,
  onError,
  history,
  }) {
  const parsedQs = queryString.parse(window.location.search);
  const {
    token,
    error,
    singleUseToken,
    region,
    ap,
    iss,
    launch,
    externalPatientId,
    jwt,
  } = parsedQs;

  removeAuthRelatedQueryString(parsedQs, history);

  let authToken = null;

  switch (true) {
    case Boolean(error):
      reporter.error(error, { context: 'Error authenticating user' });
      console.error('Error authenticating user', error);
      onError(error);
      break;
    case Boolean(token):
      authToken = token;
      break;
    case Boolean(singleUseToken): {
      const url = getSingleUseTokenUrl(options, region);
      if (url) authToken = await exchangeSingleUseToken(url, singleUseToken);
      break;
    }
    case Boolean(ap): {
      const url = getAuthProvidersUrl(options, {
        clientId,
        redirectUri,
        ap,
        iss,
        jwt,
        launch,
      });
      if (url) window.location = url;
      break;
    }
    default:
      break;
  }

  if (authToken) {
    // 1. Check existence of a saved pathname in storage. Update URL if it exists.
    replaceAndRedirectUrl(history);

    // 2. If UM sent back an Epic patient ID with the token, save it to storage.
    // NOTE: This could happen in a non-Epic flow
    // (if in the future externalPatientId is being set for other auth providers)
    if (externalPatientId) saveExternalPatientIdToStorage(externalPatientId);
  }

  return authToken;
}
