import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { colors, Flex, FormLabel, Heading, spacing } from '@packages/ui';

const errorBoundaryPageCss = css`
  margin: 20px;

  h1 {
    margin-bottom: 10px;
    font-size: 25px;
  }

  h4 {
    font-weight: 500;
  }

  pre {
    background: ${colors.blueGray200};
    color: ${colors.text};
    padding: 20px;
    border-radius: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

function ErrorBoundaryFallback({ componentStack, error }) {
  return (
    <Flex css={errorBoundaryPageCss} direction="column" rowGap={spacing.space6}>
      <Heading as="h1" variant="h3" flushMargin>
        Something bad happened on our end
      </Heading>

      <div>
        <FormLabel>Error message</FormLabel>
        <Heading as="h4" flushMargin>
          {error.toString()}
        </Heading>
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div>
          <FormLabel>Error stack trace</FormLabel>
          <pre>{error.stack}</pre>
        </div>
      )}

      {componentStack && (
        <div>
          <FormLabel>Component stack trace</FormLabel>
          <pre>{componentStack}</pre>
        </div>
      )}
    </Flex>
  );
}

ErrorBoundaryFallback.propTypes = {
  componentStack: PropTypes.node,
  error: PropTypes.object.isRequired,
};

export default ErrorBoundaryFallback;
