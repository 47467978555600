import { Global } from '@emotion/react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider, Toaster } from '@packages/ui';
import { reporter } from '@packages/reporter';
import { ErrorBoundary } from '@packages/urine';
import globalCss from 'styles/global';
import datepickerCss from 'styles/datepicker';

import tokensCss from 'styles/tokens';
import { ENV_LABELS } from 'utils/constants';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import PageLoader from 'components/PageLoader/PageLoader';

import AppAuthProvider from './AppAuthProvider';

import createStore from './redux/createStore';

import packageJson from '../package.json';

const container = document.getElementById('app');
const root = createRoot(container);

const store = createStore();

// Sentry initialization
reporter.init({
  dsn: process.env.SENTRY_DSN,
  ignoreErrors: [
    // A `fetch` promise will reject with a TypeError when a network or CORS error is encountered.
    'TypeError: Failed to fetch', // Chrome
    'TypeError: NetworkError when attempting to fetch resource', // FireFox
    'TypeError: Load failed', // Safari
  ],
  version: `heart-${packageJson.version}`,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // Disable refetch on window focus when developing, in order to improve developer experience
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
    },
    mutations: {
      retry: false,
    },
  },
});

root.render(
  <ErrorBoundary
    FallbackComponent={ErrorBoundaryFallback}
    loaderComponent={<PageLoader />}
  >
    <Helmet>
      <meta name="NODE_ENV" content={process.env.NODE_ENV} />
      <meta name="HEALTHY_ENV" content={process.env.HEALTHY_ENV} />
      <meta name="APP_VERSION" content={process.env.APP_VERSION} />
      <title>{ENV_LABELS[process.env.HEALTHY_ENV] ?? 'HEART Develop'}</title>
    </Helmet>
    <ThemeProvider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <Global styles={tokensCss} />
            <Global styles={globalCss} />
            <Global styles={datepickerCss} />

            <StylesProvider injectFirst>
              <AppAuthProvider />
            </StylesProvider>
          </ReduxProvider>
        </QueryClientProvider>
        <Toaster duration={6000} />
      </Router>
    </ThemeProvider>
  </ErrorBoundary>,
);
