import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import MuiSelect from '@material-ui/core-next/Select';

import { colors, fontSize } from '../../styles/helpers';

const selectCss = css`
  font-size: ${fontSize.md};

  &.MuiSelect-root {
    border-bottom: 1px solid;
    border-color: ${colors.black};
    outline: 0;

    /* Removes Material UI's bottom border */
    :before,
    :after {
      content: unset;
    }
  }

  &.Mui-focused {
    border-color: ${colors.primary400};
    box-shadow: 0px 1px 0px ${colors.primary400};
  }

  .MuiInput-input {
    padding-top: 0;
    padding-bottom: 0;

    &:focus {
      background-color: transparent;
    }
  }

  /* Prevents an odd behavior where the arrow shifts */
  .MuiSvgIcon-root {
    right: 0;
  }
`;

const Select = forwardRef(
  (
    {
      children,
      className,
      id,
      isDisabled,
      isFullWidth = true,
      name,
      onChange,
      value,
    },
    ref,
  ) => (
    <MuiSelect
      css={selectCss}
      className={className}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth={isFullWidth}
    >
      {children}
    </MuiSelect>
  ),
);

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Select;
