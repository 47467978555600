import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { toRem } from '../../helpers';
import { spacing } from '../../theme';

const containerCss = css`
  width: 100%;
  padding-inline: ${spacing.space4};
  // Make sure the container always has padding without it taking space
  max-width: calc(${toRem(1110)} + 2 * ${spacing.space4});
  margin-inline: auto;

  &.HioContainer--fluid {
    max-width: unset;
  }
`;

const Container = forwardRef(
  ({ as: Component = 'div', children, className, isFluid }, ref) => (
    <Component
      css={containerCss}
      className={cx(
        'HioContainer__root',
        { 'HioContainer--fluid': isFluid },
        className,
      )}
      ref={ref}
    >
      {children}
    </Component>
  ),
);

Container.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  /** Remove max-width from the container and allow it to take 100% in width */
  isFluid: PropTypes.bool,
};

export default Container;
