import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { spacing } from '../../theme';
import Item from './components/Item';

const radioGroupRootCss = ({ isHorizontal }) => css`
  display: flex;
  flex-direction: ${isHorizontal ? 'row' : 'column'};
  gap: ${spacing.space2};
`;

/**
 * RadioGroup allows a user to choose one option from a collection of options.
 * Inside of a `RadioGroup`, each option is represented by a `RadioGroup.Item`.
 *
 * @see
 * [Radix UI: RadioGroup](https://www.radix-ui.com/docs/primitives/components/radio-group)

 */
const RadioGroup = forwardRef(
  (
    {
      children,
      className,
      defaultValue,
      id,
      isDisabled,
      isHorizontal,
      isRequired,
      name,
      onChange,
      value,
      ...restRadioGroupProps
    },
    ref,
  ) => (
    <RadixRadioGroup.Root
      css={radioGroupRootCss({ isHorizontal })}
      className={cx(
        'HioRadioGroup__root',
        {
          'HioRadioGroup__root--horizontal': isHorizontal,
          'HioRadioGroup__root--vertical': !isHorizontal,
          'HioRadioGroup__root--disabled': isDisabled,
        },
        className,
      )}
      defaultValue={defaultValue}
      id={id}
      disabled={isDisabled}
      name={name}
      ref={ref}
      onChange={onChange}
      value={value}
      required={isRequired}
      {...restRadioGroupProps}
    >
      {children}
    </RadixRadioGroup.Root>
  ),
);

RadioGroup.defaultProps = {
  isHorizontal: false,
};

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

RadioGroup.Item = Item;

export default RadioGroup;
