import PropTypes from 'prop-types';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

import { MODAL_SIZES } from '../../constants';
import { borderRadius, colors, spacing, zIndex } from '../../theme';

import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import CloseTrigger from './components/CloseTrigger';

const overlayCss = css`
  background-color: ${colors.overlay};
  position: fixed;
  inset: 0;
  z-index: ${zIndex.overlay};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: fade-in 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
`;

const modalContainerCss = css`
  position: fixed;
  z-index: ${zIndex.modal};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-block-start: ${spacing.space5};
  background-color: ${colors.white};
  border-radius: ${borderRadius.lg};
  box-shadow:
    rgb(14 18 22 / 35%) 0px 10px 38px -10px,
    rgb(14 18 22 / 20%) 0px 10px 20px -15px;

  &:focus {
    outline: 0;
  }

  @keyframes modal-show {
    0% {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: modal-show 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  &.HioModal__container--sm {
    width: 400px;
  }

  &.HioModal__container--md {
    width: 600px;
  }

  &.HioModal__container--lg {
    width: 800px;
  }

  &.HioModal__container--xl {
    width: 1000px;
  }
`;

/**
 * Modal allows the user to focus on one task or a piece of information,
 * by popping-up and blocking the page content until the modal task is completed,
 * or until the user dismisses the action. Use modal for short and non-frequent tasks. For common tasks consider using the main flow.
 */
const Modal = forwardRef(
  (
    {
      autoFocusOnOpen,
      children,
      className,
      isOpen,
      isOpenDefault,
      onClose,
      triggerButton,
      size,
    },
    ref,
  ) => (
    <RadixDialog.Root
      className={cx(
        'HioModal__root',
        {
          'HioModal--open': isOpen,
        },
        className,
      )}
      defaultOpen={isOpenDefault}
      {...(onClose && { onOpenChange: onClose })}
      open={isOpen}
      ref={ref}
    >
      {triggerButton && (
        <RadixDialog.Trigger asChild className="HioModal__trigger">
          {triggerButton}
        </RadixDialog.Trigger>
      )}
      <RadixDialog.Overlay
        css={overlayCss}
        data-testid="modal-overlay"
        className={cx('HioModal__overlay')}
      />
      <RadixDialog.Content
        css={modalContainerCss}
        className={cx('HioModal__container', {
          [`HioModal__container--${size}`]: size,
        })}
        // Disable auto focus on open if autoFocusOnOpen is false
        {...(!autoFocusOnOpen && {
          onOpenAutoFocus: (event) => event.preventDefault(),
        })}
      >
        {children}
      </RadixDialog.Content>
    </RadixDialog.Root>
  ),
);

Modal.CloseTrigger = CloseTrigger;

Modal.Content = Content;

Modal.Footer = Footer;

Modal.Header = Header;

Modal.defaultProps = {
  autoFocusOnOpen: false,
  size: MODAL_SIZES.SM,
};

Modal.propTypes = {
  /** Indicates whether the modal should be focused when it opens (focus on the first interactive element) */
  autoFocusOnOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  /** Indicates that the modal is active and can be interacted with. To be used when the dialog is controlled */
  isOpen: PropTypes.bool,
  /** The open state of the modal when it is initially rendered. To be used when the modal is not controlled */
  isOpenDefault: PropTypes.bool,
  /** Event handler called when the modal is closing */
  onClose: PropTypes.func,
  /** The button that opens the modal. To be used when the modal is not controlled */
  triggerButton: PropTypes.node,
  /** The size of the modal */
  size: PropTypes.oneOf(Object.values(MODAL_SIZES)),
};

export default Modal;
