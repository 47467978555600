import { combineReducers } from '@reduxjs/toolkit';
import { reducer as healthyAuth } from '@packages/healthy-auth';

import { reducer as callPage } from 'containers/Call/slice';
import { reducer as common } from 'containers/App/slice';
import { reducer as patientList } from 'containers/PatientList/slice';
import { reducer as pcpCenter } from 'containers/PcpCenter/slice';
import { reducer as patientQueue } from 'containers/CallsQueue/slice';

const appReducer = combineReducers({
  callPage,
  common,
  healthyAuth,
  patientList,
  patientQueue,
  pcpCenter,
});

const rootReducer = (state, action) => {
  // Clear all app state except healthy auth
  if (action.type === 'cleanAppState') {
    return appReducer(
      {
        healthyAuth: {
          ...state.healthyAuth,
        },
      },
      action,
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
